import { useEffect, useState } from "react"
import { useParams, useHistory } from 'react-router-dom'

import { MenuNav } from "../../components/MenuNav"
import { useAuth } from "../../hooks/useAuth"
import Kit from "../../core/Kit"
import Colecaokit from "../../db/ColecaoKit"
import ColecaoProduto from "../../db/ColecaoProduto"
import TabelaProdutosDoKit from "./components/TabelaProdutosDoKit"
import TabelaProdutos from "./components/TabelaProdutos"
import Card from "./components/Card"
import { getArrayOrdenadoPorNome, removeElementoDoArrayPorID } from "../../components/FuncoesArray"

export function KitEdit() {

    const { user } = useAuth()

    const repoKit = new Colecaokit()
    const repoProduto = new ColecaoProduto()

    const history = useHistory();

    const idKit = useParams().id

    const [kit, setkit] = useState(Kit.vazio())
    const [produtos, setProdutos] = useState([])
    const [produtosDoKit, setProdutosDoKit] = useState([])

    useEffect(() => {
        repoKit.get(idKit).then(kit => {
            setkit(kit)
            repoProduto.obterTodos().then(produtos => {
                let produtosTemp = produtos
                kit.produtos.forEach(p => {
                    produtosTemp = removeElementoDoArrayPorID(p.id, produtosTemp)
                })
                setProdutos(produtosTemp)
            })
            setProdutosDoKit(kit.produtos)
        })
    }, [idKit])


    function incluirProduto(produto) {
        let produtosDoKitTemp = produtosDoKit
        produtosDoKitTemp.push(produto)
        produtosDoKitTemp = getArrayOrdenadoPorNome(produtosDoKitTemp)
        setProdutosDoKit(
            produtosDoKitTemp
        )
        setkit({
            ...kit,
            produtos: produtosDoKitTemp
        })
        setProdutos(
            removeElementoDoArrayPorID(produto.id, produtos)
        )
    }

    function removeProduto(produto) {
        let produtosDoKitTemp = removeElementoDoArrayPorID(produto.id, produtosDoKit)
        setProdutosDoKit(
            produtosDoKitTemp
        )
        setkit({
            ...kit,
            produtos: produtosDoKitTemp
        })
        let produtosTemp = produtos
        produtosTemp.push(produto)
        setProdutos(
            getArrayOrdenadoPorNome(produtosTemp)
        )
    }

    function onChange(event) {
        setkit({
            ...kit,
            [event.target.name]: event.target.value,
        })
    }

    async function onSubmit(event) {
        console.log(JSON.stringify(kit))
        event.preventDefault()
        await repoKit.salvar(kit)
        history.push(`/kits`);
    }

    return (
        <div>
            <MenuNav user={user} />
            <div className="margem">
                <Card titulo="Editar">
                    <form onSubmit={onSubmit}>
                    <div className="mb-3">
                            <label className="form-label">Nome*</label>
                            <input type="text" className="form-control"
                                name="nome" value={kit.nome} onChange={onChange} required />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Preço Venda*</label>
                            <input type="text" className="form-control"
                                name="preco_venda" value={kit.preco_venda} onChange={onChange} required />
                        </div>
                        <div className="row">
                            <hr />
                            <TabelaProdutosDoKit
                                produtos={produtosDoKit}
                                removeProduto={removeProduto}
                            />
                        </div>
                        <div className="row">
                            <div className="col">
                                <a href="/kits" className="btn btn-secondary">
                                    <i className="bi bi-arrow-left-circle-fill"></i> Voltar
                                </a>
                            </div>
                            <div className="col text-end">
                                <button type="submit" className="btn btn-primary">
                                    <i className="bi bi-check-circle-fill"></i> Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </Card>
            </div>
            <Card titulo="Lista de Produtos" >
                <TabelaProdutos
                    produtos={produtos}
                    incluirProduto={incluirProduto} />
            </Card>
        </div>
    )
}
export default function Card(props) {
    return (
        <div className="margem">
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col"><h5 className="text-success">{props.titulo}</h5></div>
                    </div>
                </div>
                <div className="card-body">
                    {props.children}
                </div>
            </div>
        </div>
    )
}
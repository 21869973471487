import { createContext, useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { auth } from "./firebase";

export const AuthContext = createContext({
    user: {
        id: "", 
        name: "", 
        email: "",
    }, 
});

export function AuthContextProvider(props) {

    const history = useHistory();
    const location = useLocation();

    const [user, setUser] = useState({
        id: "", 
        name: "", 
        email: "", 
    });

    useEffect(() => {
        if(location.pathname !== "/"){
            const unsubscribe = auth.onAuthStateChanged(user => {
                if (user) {
                    const { displayName, uid, email } = user;
                    setUser({
                        id: uid,
                        name: displayName,
                        email: email, 
                    })
                }else{
                    
                    history.push(`/sistema`);
                }
            })
            return () => {
                unsubscribe();
            }
        }        
    }, []);

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            {props.children}
        </AuthContext.Provider>
    );
}
import React, { useEffect } from 'react'
import { MenuNav } from "../../components/MenuNav"
import { useAuth } from "../../hooks/useAuth"
import { useState } from "react"
import { CSVLink } from "react-csv";
import ColecaoProduto from '../../db/ColecaoProduto';



export function ProdutoExport() {

    const { user } = useAuth()

    const [produtos, setProdutos] = useState([])

    const repoProdutos = new ColecaoProduto()

    const headers = [
        { label: "produto", key: "produto" },
        { label: "codigodebarras", key: "codigodebarras" },
        { label: "precodevenda", key: "precodevenda" },
      ];


    const [produtosNovos, setProdutosNovos] = useState([])
    const [loading, setLoading] = useState(true)

    const buttonRef = React.createRef()

    useEffect(() => {
        async function getprodutos() {
            repoProdutos.obterTodos().then(produtos =>{
                setProdutos(produtos.map(prod => {
                    return {
                        produto: prod.id+" - "+prod.nome,
                        codigodebarras: prod.id, 
                        precodevenda: String(prod.preco_venda).replace(".", ","),
                    }
                }))
                setLoading(false)
            })
        }
        getprodutos()
    }, [])

    async function downloadReport() {
        //const data = await this.getUserList();
        this.setState({ data: produtos }, () => {
          setTimeout(() => {
            this.csvLinkEl.current.link.click();
          });
        });
    }
  

    return (
        <div>
            <MenuNav user={user} />
            <div className="margem">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col"><h5 className="text-success">Exportar de produtos</h5></div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div>
                            <h3>Exportação:</h3>
                            <p>O arquivo de exportação terá apenas os compos ( Produto | Código do Produtos | Preço de Venda )</p>
                        </div>
                        <div>
                            
                            <CSVLink
                                headers={headers}
                                filename="produtos.csv"
                                data={produtos}
                            >Clicque aqui 
                                </CSVLink>
                            <hr />
                            <div className="row">
                                
                                <div>
                                    {loading && 
                                        <p>Carregando Dados</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
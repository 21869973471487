import { useEffect, useState } from "react"
import { MenuNav } from "../../components/MenuNav"
import { database } from "../../services/firebase"
import { useAuth } from "../../hooks/useAuth"

export function ProducaoList(){

    const {user} = useAuth()

    const [producoes, setProducoes] = useState([])

    useEffect(()=>{
        async function getProducoes(){
            let ref = await database.collection("producao").get()
            let lista = ref.docs.map((doc) => {
                return {
                    id: doc.id,
                    data: doc.data().data,
                    funcionario_id: doc.data().funcionario_id,
                    funcionario: doc.data().funcionario,
                    produto_id: doc.data().produto_id,
                    produto: doc.data().produto,
                    quantidade: doc.data().quantidade,
                    valor: doc.data().valor,
                }
            })
            lista = lista.sort((a, b)=>{
                if(a.data < b.data){
                    return -1
                }else{
                    return true
                }
            })
            setProducoes(lista)
        }
        getProducoes()
    }, [])

    return (
        <div>
            <MenuNav user={user}/>
            <div className="margem">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col"><h5 className="text-success">Produções </h5></div>
                            <div className="col text-end">
                                <a href="/producao/novo" className="btn btn-warning">
                                    <i className="bi-plus-lg"></i> Nova Produção
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <table className="table table-striped table-hover">
                            <thead>
                            <tr className="text-center">
                                <th scope="col text-start">Produto</th>
                                <th scope="col">Funcionário</th>
                                <th scope="col">Opções</th>
                            </tr>
                            </thead>
                            <tbody>
                            {producoes?.map((producao, index) => 
                                <tr key={index}>
                                    <td>{producao.quantidade} - {producao.produto.nome}</td>
                                    <td className="text-center">{producao.funcionario.nome}</td>
                                    <td className="text-center">
                                        <a className="btn btn-sm btn-primary" href={`/producao/receber/${producao.id}`} title="Receber">
                                            <i className="bi bi-gear-wide-connected"></i>
                                        </a>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>       
    )
}
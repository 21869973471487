function nBR_nEN(valor) {
    try {
        let valorTemp = valor
        if (typeof valor === "string") {
            valorTemp = parseFloat(valor.replace(",", "."))
        }
        return valorTemp
    } catch (e) {
        return "Erro " + e.message
    }
}
function dateToDateBRCompleto(data) {
    const ano = data.getFullYear()
    let mes = data.getMonth()+1
    let dia = data.getDate()
    mes = mes < 10 ? "0"+mes : mes
    dia = dia < 10 ? "0"+dia : dia
    const hora = data.getHours()
    const minutos = data.getMinutes()

    return dia + "/" + mes + "/" + ano + " " + hora + ":" + minutos;
}

function dateToDateBR(data) {
    const ano = data.getFullYear()
    let mes = data.getMonth()+1
    let dia = data.getDate()
    mes = mes < 10 ? "0"+mes : mes
    dia = dia < 10 ? "0"+dia : dia

    return dia + "/" + mes + "/" + ano;
}


export { nBR_nEN, dateToDateBR, dateToDateBRCompleto }
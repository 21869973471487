export default class Produto{
    constructor (nome,quantidade, quantidade_min, custo_producao, preco_venda, e_vendido, id="") {
        this.id = id;
        this.nome = nome;
        this.quantidade = quantidade;
        this.quantidade_min = quantidade_min;
        this.custo_producao = custo_producao;
        this.preco_venda = preco_venda;
        this.e_vendido = e_vendido;
    }

    static vazio(){
        return new Produto("",0,0,0,0,true,"","")
    }
}
import { useEffect, useState } from "react"
import { useParams, useHistory } from 'react-router-dom'

import MaskedInput from 'react-text-mask'

import { MenuNav } from "../../components/MenuNav"
import { database } from "../../services/firebase"
import { useAuth } from "../../hooks/useAuth"

export function FuncionarioEdit(){

    const {user} = useAuth() 

    const history = useHistory();

    const idFuncionario = useParams().id

    const [values, setValues] = useState({
        nome: "Carregando ...",
        whatsapp: "Carregando ...",
        endereco: "Carregando ...", 
    })

    useEffect(()=>{
        let ref = database.collection("funcionarios").doc(idFuncionario).get()
        ref.then((doc)=>{
            if(doc.exists){
                setValues({
                    id: doc.id,
                    nome: doc.data().nome,
                    whatsapp: doc.data().whatsapp,
                    endereco: doc.data().endereco, 
                })
            }else{
                console.log("inexistente")
            }
        })
    }, [idFuncionario])

    function onChange(event){
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        })
    }

    async function onSubmit(event){
        event.preventDefault()
        database.collection("funcionarios").doc(idFuncionario).set(values)
        
        history.push(`/funcionarios`);
    }

    return (
        <div>
            <MenuNav user={user}/>
            <div className="margem">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col"><h5 className="text-success">Editar</h5></div>
                        </div>
                    </div>
                    <div className="card-body">
                        <form onSubmit={onSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Nome*</label>
                                <input type="text" className="form-control"
                                    name="nome" value={values.nome} onChange={onChange} required/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Whatsapp*</label>
                                <MaskedInput type="text" className="form-control"
                                    mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/,' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} 
                                    placeholder='(00) 00000-0000'
                                    name="whatsapp" value={values.whatsapp} onChange={onChange} required/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Endereço</label>
                                <input type="text" className="form-control"
                                    name="endereco" value={values.endereco} onChange={onChange} required/>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <a href="/funcionarios" className="btn btn-secondary">
                                        <i className="bi bi-arrow-left-circle-fill"></i> Voltar
                                    </a>
                                </div>
                                <div className="col text-end">
                                    <button type="submit" className="btn btn-primary">
                                        <i className="bi bi-check-circle-fill"></i> Salvar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>       
    )
}
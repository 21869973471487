export default function TabelaProdutos(props) {
    return (
        <table className="table table-striped table-hover">
            <tbody>
                {props.produtos?.map(produto => {
                    return (
                        <tr key={produto.id}>
                            <td>{produto.nome}</td>
                            <td className="text-end">
                                <button className="btn btn-sm btn-success"
                                    onClick={() => {
                                        props.incluirProduto(produto)
                                    }}>
                                    <i className="bi bi-plus-circle-fill"></i>
                                </button>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}
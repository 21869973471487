function getValorEmReais(valor){
    try{
        let valorTemp = valor
        if(typeof valor === "string"){
            valorTemp = parseFloat(valor.replace(",","."))
        }
        return valorTemp.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    }catch(e){
        return "R$ 0,00"
    }
    
}

function getValorFormatado(valor){
    try{
        let valorTemp = valor
        if(typeof valor === "string"){
            valorTemp = parseFloat(valor.replace(",","."))
        }
        return valorTemp.toLocaleString('pt-br');
    }catch(e){
        return "0,00"
    }
    
}

function getDataToTimestamp(data){
    try{
        return data.getDate()+"/"+(data.getMonth()+1)+"/"+data.getFullYear()
    }catch(e){
        return "00/00/0000"
    }
}

export { getValorEmReais, getValorFormatado, getDataToTimestamp }
import { useEffect, useState } from "react"
import { useParams, useHistory } from 'react-router-dom'

import MaskedInput from 'react-text-mask'

import { GetEndereco } from "../../services/getEndereco"
import { MenuNav } from "../../components/MenuNav"
import { database, chaves } from "../../services/firebase"
import { useAuth } from "../../hooks/useAuth"

export function ClienteEdit(){

    const {user} = useAuth() 

    const history = useHistory();

    const idCliente = useParams().id

    const [values, setValues] = useState({
        nome: "Carregando ...",
        tel_principal: "Carregando ...",
        tel_secundario: "Carregando ...",
        email: "Carregando ...",
        cnpj_cpf: "Carregando ...",
        estado: "Carregando ...", 
        cidade: "Carregando ...", 
        bairro: "Carregando ...", 
        endereco: "Carregando ...", 
        numero: "Carregando ...", 
        cep: "Carregando ...", 
        insc_estadual: "Carregando ...", 
    })

    useEffect(()=>{
        let ref = database.collection(chaves.CLIENTES).doc(idCliente).get()
        ref.then((doc)=>{
            if(doc.exists){
                setValues({
                    id: doc.id,
                    nome: doc.data().nome,
                    tel_principal: doc.data().tel_principal,
                    tel_secundario: doc.data().tel_secundario,
                    email: doc.data().email,
                    cnpj_cpf: doc.data().cnpj_cpf,
                    estado: doc.data().estado, 
                    cidade: doc.data().cidade, 
                    bairro: doc.data().bairro, 
                    endereco: doc.data().endereco, 
                    numero: doc.data().numero, 
                    cep: doc.data().cep, 
                    insc_estadual: doc.data().insc_estadual, 
                })
            }else{
                console.log("inexistente")
            }
        })
    }, [idCliente])

    function onChange(event){
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        })
    }

    function carregaEndereco(event){
        console.log("carregou")
        GetEndereco(event.target.value).then(resp => {
            if(resp.data.erro===true){
                console.log("Nao encntrado")
            }else{
                setValues({
                    ...values,
                    estado: resp.data.uf, 
                    cidade: resp.data.localidade, 
                    bairro: resp.data.bairro, 
                    endereco: resp.data.logradouro, 
                })
            }
            console.log(JSON.stringify(resp))
        }).catch(error => {
            console.log("Erro")
        })
    }

    async function onSubmit(event){
        event.preventDefault()
        database.collection(chaves.CLIENTES).doc(idCliente).set(values)
        
        history.push(`/clientes`);

    }

    return (
        <div>
            <MenuNav user={user}/>
            <div className="margem">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col"><h5 className="text-success">Editar</h5></div>
                        </div>
                    </div>
                    <div className="card-body">
                    <form onSubmit={onSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Nome*</label>
                                <input type="text" className="form-control"
                                    name="nome" value={values.nome} onChange={onChange} required/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Tel Principal*</label>
                                <MaskedInput type="text" className="form-control"
                                    mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/,' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} 
                                    placeholder='(00) 00000-0000'
                                    name="tel_principal" value={values.tel_principal} onChange={onChange} required/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Tel Secundário</label>
                                <MaskedInput type="text" className="form-control"
                                    mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/,' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} 
                                    placeholder='(00) 00000-0000'
                                    name="tel_secundario" value={values.tel_secundario} onChange={onChange} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">e-mail</label>
                                <input type="email" className="form-control"
                                    name="email" value={values.email} onChange={onChange} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">CPF/CNPJ</label>
                                <input type="text" className="form-control"
                                    name="cnpj_cpf" value={values.cnpj_cpf} onChange={onChange} required/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">insc_estadual</label>
                                <input type="text" className="form-control"
                                    name="insc_estadual" value={values.insc_estadual} onChange={onChange} required/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">CEP</label>
                                <MaskedInput type="text" className="form-control"
                                    mask={[/[1-9]/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]} 
                                    name="cep" value={values.cep} onChange={onChange} onBlur={carregaEndereco} required/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Estado</label>
                                <input type="text" className="form-control"
                                    name="estado" value={values.estado} onChange={onChange} required/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Cidade</label>
                                <input type="text" className="form-control"
                                    name="cidade" value={values.cidade} onChange={onChange} required/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Bairro</label>
                                <input type="text" className="form-control"
                                    name="bairro" value={values.bairro} onChange={onChange} required/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Endereço</label>
                                <input type="text" className="form-control"
                                    name="endereco" value={values.endereco} onChange={onChange} required/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Número</label>
                                <input type="text" className="form-control"
                                    name="numero" value={values.numero} onChange={onChange} required/>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <a href="/clientes" className="btn btn-secondary">
                                        <i className="bi bi-arrow-left-circle-fill"></i> Voltar
                                    </a>
                                </div>
                                <div className="col text-end">
                                    <button type="submit" className="btn btn-primary">
                                        <i className="bi bi-check-circle-fill"></i> Salvar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>       
    )
}
import { useState } from "react"
import { useHistory } from 'react-router-dom'

import { MenuNav } from "../../components/MenuNav"
import { database } from "../../services/firebase"
import { useAuth } from "../../hooks/useAuth"

export function ProdutoNovo(){

    const {user} = useAuth()

    const history = useHistory();

    const [values, setValues] = useState({
        nome: "",
        quantidade: 0,
        quantidade_min: 0,
        custo_producao: 0,
        preco_venda: 0,
        e_vendido: true, 
    })

    function onChange(event){
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        setValues({
            ...values,
            [event.target.name]: value,
        })
    }

    async function onSubmit(event){
        event.preventDefault()
        const clienteRef = database.collection("produtos")
        await clienteRef.add({
            ...values, 
            preco_venda: parseFloat(values.preco_venda.replace(",", ".")),
            custo_producao: parseFloat(values.custo_producao.replace(",", ".")),
            quantidade: parseInt(values.quantidade),
            quantidade_min: parseFloat(values.quantidade_min),
        })
        history.push(`/produtos`);
    }

    return (
        <div>
            <MenuNav user={user}/>
            <div className="margem">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col"><h5 className="text-success">Novo Produto</h5></div>
                        </div>
                    </div>
                    <div className="card-body">
                        <form onSubmit={onSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Nome*</label>
                                <input type="text" className="form-control"
                                    name="nome" value={values.nome} onChange={onChange} required/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Quantidade</label>
                                <input type="text" className="form-control"
                                    name="quantidade" value={values.quantidade} onChange={onChange} required/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Quandidade Mínima</label>
                                <input type="text" className="form-control"
                                    name="quantidade_min" value={values.quantidade_min} onChange={onChange} required/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Preço Venda </label>
                                <input type="text" className="form-control"
                                    name="preco_venda" value={values.preco_venda} onChange={onChange} required/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label"> Custo Produçao </label>
                                <input type="text" className="form-control"
                                    name="custo_producao" value={values.custo_producao} onChange={onChange} required/>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox"
                                    checked={values.e_vendido} onChange={onChange} 
                                    name="e_vendido" />
                                <label className="form-check-label">
                                    É vendido?
                                </label>
                            </div>
                            <hr />
                            
                            <div className="row">
                                <div className="col">
                                    <a href="/clientes" className="btn btn-secondary">
                                        <i className="bi bi-arrow-left-circle-fill"></i> Voltar
                                    </a>
                                </div>
                                <div className="col text-end">
                                    <button type="submit" className="btn btn-primary">
                                        <i className="bi bi-check-circle-fill"></i> Salvar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>       
    )
}
import { useState } from "react"
import { useHistory } from "react-router"
import "./homelogin.css"

import { auth } from "../../services/firebase"
import { useAuth } from "../../hooks/useAuth"

export function HomeLogin(){

    const {user, setUser} = useAuth() 

    const history = useHistory();

    const [values, setValues] = useState({
        email: "",
        senha: "",
        erro: "Aguardando login",
        mensagem_erro: false
    })

    function onChange(event){
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        })
    }

    function onSubmit(event){
        event.preventDefault()
        auth.signInWithEmailAndPassword(values.email, values.senha)
        .then((userCredential) => {
            const { displayName, uid, email } = userCredential.user;
                setUser({
                    id: uid,
                    name: displayName,
                    email: email,
                })
                console.log(JSON.stringify(userCredential))
                history.push("/dashboard")
        })
        .catch((error) => {
            //var errorCode = error.code;
            //var errorMessage = error.message;
            setValues({
                ...values,
                erro: "Erro - verifique seu login e senha",
                mensagem_erro: true,
            })
        });
    }

    return (
        <div className="container">
            <div className="content" style={{ "box-shadow": "inset 0 0 2em gold, 0 0 2em gray"}}>
                <div className="card-header bg-success">
                    <h5 className="text-white">AluSaraiva</h5>
                </div>
                <div className="card-body">
                    <form>
                        <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input type="email" className="form-control" name="email"
                                value={values.email}
                                onChange={onChange}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Password</label>
                            <input type="password" className="form-control" name="senha"
                                value={values.senha}
                                onChange={onChange}
                                />
                        </div>
                        {values.mensagem_erro && 
                            <div className="mb-3">
                                <div className="alert alert-danger" role="alert">
                                    {values.erro}
                                </div>
                            </div>
                        }
                        <div className="text-end">
                            <button className="btn btn-primary" onClick={onSubmit} >Entrar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>       
    )
}
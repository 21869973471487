import "./homelogin.css"

export function Home() {
    return (
        <div style={{ backgroundColor: "#f7f7f7" }}>
            <div className="container" >
                <div className="content">
                    <img src={window.location.origin + '/imgs/logoAluSaraiva.jpeg'} class="img-fluid" width="400rem" alt=""/>
                    <p className="text-center">Rua Antônio Gomes Chaves n°3772 - Teresina - PI</p>
                    <p className="text-center">Para atendimento via Whatsapp <a href="https://wa.me/558632361587">clique aqui </a></p>
                </div>
            </div>
        </div>

    )
}
import Cliente from "../core/Cliente";
import { database, chaves } from "../services/firebase"

export default class ColecaoCliente {

    colecao() {
        return database.collection(chaves.CLIENTES).withConverter(this.ClienteConverter)
    }

    ClienteConverter = {
        toFirestore: function (cliente) {
            return {
                nome: cliente.nome,
                tel_principal: cliente.tel_principal,
                tel_secundario: cliente.tel_secundario,
                email: cliente.mail,
                cnpj_cpf: cliente.cnpj_cpf,
                estado: cliente.estado,
                cidade: cliente.cidade,
                bairro: cliente.bairro,
                endereco: cliente.endereco,
                numero: cliente.numero,
                cep: cliente.cep,
                insc_estadual: cliente.insc_estadual,
                id: cliente.id,
            };
        },
        fromFirestore: function (snapshot, options) {
            const data = snapshot.data(options);
            return new Cliente(
                data.nome,
                data.tel_principal,
                data.tel_secundario,
                data.mail,
                data.npj_cpf,
                data.estado,
                data.cidade,
                data.bairro,
                data.endereco,
                data.numero,
                data.cep,
                data.insc_estadual,
                snapshot.id);
        }
    }

    async salvar(cliente) {
        if (cliente?.id) {
            await this.colecao().doc(cliente.id).set(cliente)
            return cliente
        } else {
            const docRef = await this.colecao().add(cliente)
            const doc = await docRef.get()
            await this.colecao().doc(doc.data().id).set(doc.data())
            return doc.data()
        }
    }

    async excluir(cliente) {
        return this.colecao().doc(cliente.id).delete()
    }

    async obterTodos() {
        const query = await this.colecao().orderBy("nome").get()
        return query.docs.map(doc => doc.data()) ?? []
    }

    async get(id) {
        const query = await this.colecao().doc(id).get()
        return query.data() ?? Cliente.vazio()
    }
}
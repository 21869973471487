import { useHistory } from 'react-router-dom'
import { auth } from "../services/firebase";

export function MenuNav(props){

    const history = useHistory();

    function signOut(){
        auth.signOut().then(() => {
            history.push(`/sistema`);
        });
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-success">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">AluSaraiva</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/clientes">Clientes</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/produtos">Produtos</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/kits">Kits</a>
                        </li>
                        
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/funcionarios">Funcionários</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/producao">Produção</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/vendas">Vendas</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link active dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Configurações
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href="/produto_import">Importar Produtos</a></li>
                                <li><a className="dropdown-item" href="/produto_export">Exportar Produtos</a></li>
                            </ul>
                        </li>
                    </ul>
                    <div>
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex">
                            <li className="nav-item dropdown">
                                <span className="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {props.user.email}
                                </span>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                    <li><button className="dropdown-item" onClick={signOut}>Sair</button></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}
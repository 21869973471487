import { useEffect, useState } from "react"
import { useParams } from 'react-router-dom'

import DatePicker from 'react-date-picker';

import { MenuNav } from "../../components/MenuNav"
import { database } from "../../services/firebase"
import { useAuth } from "../../hooks/useAuth";

export function FuncionarioProducao(){
    const {user} = useAuth()

    const [producoes, setProducoes] = useState([])
    const [producoesFiltro, setProducoesFiltro] = useState([])
    const [funcionario, setFuncionario] = useState({
        nome: "Carregando ..."
    })

    const [total, setTotal] = useState("0,00")

    const date = new Date()
    const [dataInicio, setDataInicio] = useState(new Date(date.getFullYear(), date.getMonth(), 1, 0, 1, 1))
    const [dataFinal, setDataFinal] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59))

    const idFuncionario = useParams().id

    // inicial
    useEffect(()=>{
        // Carregar o funcionario 
        let refFunc = database.collection("funcionarios").doc(idFuncionario).get()
        refFunc.then((doc)=>{
            setFuncionario({
                id: doc.id,
                nome: doc.data().nome, 
            })
        })
        // carrega Produções do funcionario
        let ref = database.collection("producao_funcionario").doc(idFuncionario).get()
        ref.then((doc)=>{
            if(doc.exists){
                let temp = doc.data().producoes.map((producao) => {
                    let data = producao.data.toDate()
                    return {
                        data: data.getDate()+"/"+(data.getMonth()+1)+"/"+data.getFullYear(),
                        data_real: data,
                        produto: producao.produto, 
                        quantidade: producao.quantidade, 
                        valor: producao.valor, 
                    }
                })
                setProducoes(temp)
                setProducoesFiltro(temp)
            }else{
                console.log("inexistente")
            }
        })
    }, [idFuncionario])


    // Quando a data for atualizada 
    useEffect(()=>{
        
        if(dataInicio===null || dataFinal===null){
            setProducoesFiltro([])
        }else{
            let data_i = new Date(
                dataInicio.getFullYear(), dataInicio.getMonth(), dataInicio.getDate(), 0, 1, 1
            )
            let data_f = new Date(
                dataFinal.getFullYear(), dataFinal.getMonth(), dataFinal.getDate(), 23, 59, 59
            )
            let filtro = producoes.filter( (producao) => {
                return producao.data_real.getTime() > data_i.getTime() && producao.data_real.getTime() < data_f.getTime() 
            })
            setProducoesFiltro(filtro)
        }
    }, [dataInicio, dataFinal])


    // Atualizar Total
    useEffect(()=>{
        setTotal(producoesFiltro.reduce((total, producao) => {
            return total + producao.valor
        },0))
    }, [producoesFiltro])

    return (
        <div>
            <MenuNav user={user}/>
            <div className="margem">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col"><h5 className="text-success">{funcionario?.nome} </h5></div>
                            <div className="col text-end">
                                Produção do período - R$ {total}
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <form>
                                <span>Início </span> 
                                <DatePicker
                                    onChange={setDataInicio}
                                    value={dataInicio}
                                    locale="pt-br"
                                />
                                <span> Término </span> 
                                <DatePicker
                                    onChange={setDataFinal}
                                    value={dataFinal}
                                    locale="pt-br"
                                    clearAriaLabel="Clear value"
                                />
                            </form> 
                        </div>
                        <hr />
                        <table className="table table-striped table-hover">
                            <thead>
                            <tr className="text-center">
                                <th scope="col">Data</th>
                                <th scope="col">Produto</th>
                                <th scope="col">Quantidade</th>
                                <th scope="col">Valor</th>
                            </tr>
                            </thead>
                            <tbody>
                            {producoesFiltro?.map((producao, index) => 
                                <tr key={index}>
                                    <td>{producao.data}</td>
                                    <td className="text-center">{producao.produto.nome}</td>
                                    <td className="text-center">{producao.quantidade}</td>
                                    <td className="text-center">R$ {producao.valor}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>       
    )
}
import { useEffect, useState } from "react"
import { MenuNav } from "../../components/MenuNav"
import { useAuth } from "../../hooks/useAuth"
import Colecaokit from "../../db/ColecaoKit"
import { getArrayOrdenadoPorNome } from "../../components/FuncoesArray"
import { getValorEmReais } from "../../components/Formatacao"

export function KitList() {
    const { user } = useAuth()

    const repo = new Colecaokit()

    const [kits, setKits] = useState([])
    const [kitsFilter, setKitsFilter] = useState([])
    const [pesquisado, setPesquisado] = useState("")

    useEffect(() => {
        obterTodos()
    }, [])

    function obterTodos() {
        repo.obterTodosComQuantidades().then(kits => {
            let lista = getArrayOrdenadoPorNome(kits)
            setKits(lista)
            setKitsFilter(lista)
        })
    }

    function onChange(event) {
        event.preventDefault()
        let pesq = event.target.value
        setPesquisado(pesq)
        if (pesq !== "") {
            let temp = kits.filter((obj) => {
                return obj.nome.toUpperCase().includes(pesq.toUpperCase())
            })
            if (temp === []) {
                setKitsFilter(kits)
            } else {
                setKitsFilter(temp)
            }
        } else {
            setKitsFilter(kits)
        }
    }

    async function excluir(kit) {
        await repo.excluir(kit).then(() => {
            // excluir das duas listas 
            let kitsTemp = kits.filter((obj) => {
                return obj.id != kit.id
            })
            setKits(kitsTemp)
            setKitsFilter(kitsTemp)
        })

    }

    return (
        <div>
            <MenuNav user={user} />
            <div className="margem">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col"><h5 className="text-success">Lista de Kits</h5></div>
                            <div className="col text-end">
                                <a href="/kits/formulario" className="btn btn-warning">
                                    <i className="bi-plus-lg"></i> Novo Kit
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <form method="get">
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control"
                                            placeholder="Pesquisar Kits" aria-label="Pesquisar kits"
                                            aria-describedby="button-addon2"
                                            value={pesquisado} onChange={onChange} />
                                        <button className="btn btn-outline-secondary"
                                            type="button" id="button-addon2">Pesquisar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th scope="col">Nome</th>
                                    <th scope="col">Quantidade</th>
                                    <th scope="col">Preço</th>
                                    <th scope="col">Opções</th>
                                </tr>
                            </thead>
                            <tbody>
                                {kitsFilter?.map((kit, index) =>
                                    <tr key={index} >
                                        <td>
                                            <a href={`/kits/edit/${kit?.id}`}>
                                                {kit?.nome}
                                            </a>
                                        </td>
                                        <td className="text-center">
                                            {kit?.getQuant()}
                                        </td>
                                        <td  className="text-center">
                                            { getValorEmReais(kit?.preco_venda)}
                                        </td>
                                        <td className="text-center">
                                            <button className="btn btn-sm btn-danger"
                                                onClick={() => { if (window.confirm(`Confirma a exclusão do produto (${kit?.nome})?`)) excluir(kit) }}>
                                                <i className="bi bi-x-circle-fill"></i>
                                            </button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, { useEffect } from 'react'
import { MenuNav } from "../../components/MenuNav"
import { useAuth } from "../../hooks/useAuth"
import { database, chaves } from "../../services/firebase"
import { nBR_nEN } from "../../components/Conversao"
import { CSVReader } from 'react-papaparse'

import { useState } from "react"

export function ProdutoImport() {

    const { user } = useAuth()

    const [produtos, setProdutos] = useState([])
    const [produtosNovos, setProdutosNovos] = useState([])
    const [loading, setLoading] = useState(false)

    const buttonRef = React.createRef()

    useEffect(() => {
        async function getprodutos() {
            let ref = await database.collection(chaves.PRODUTOS).get()
            let lista = ref.docs.map((doc) => {
                return {
                    id: doc.id,
                    nome: doc.data().nome,
                    quantidade: String(doc.data().quantidade),
                    quantidade_min: String(doc.data().quantidade_min),
                    custo_producao: parseFloat(doc.data().custo_producao),
                    preco_venda: parseFloat(String(doc.data().preco_venda)),
                    e_vendido: doc.data().e_vendido,
                }
            })
            lista = lista.sort((a, b) => {
                if (a.nome.toUpperCase() < b.nome.toUpperCase()) {
                    return -1
                } else {
                    return true
                }
            })
            setProdutos(lista)

        }
        getprodutos()
    }, [])

    const handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e)
        }
    }

    const handleOnFileLoad = (dados) => {
        let lista = dados.map(dado => {
            try {
                return {
                    nome: dado.data[0].split(" - ")[1],
                    quantidade: "0",
                    quantidade_min: "0",
                    custo_producao: "0",
                    preco_venda: nBR_nEN(dado.data[2]),
                    e_vendido: true,
                }
            }
            catch (e) {
                console.log("erro" + (e.message))
            }
        })
        // removo o primeiro da lista que é o cabeçalho 
        lista.shift()
        // remove os dois ultimos - linhas dos totais
        lista.pop()
        lista.pop()
        setProdutosNovos(lista)
    }

    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err)
    }

    const handleOnRemoveFile = (data) => {

    }

    const handleRemoveFile = (e) => {
        if (buttonRef.current) {
            buttonRef.current.removeFile(e)
        }
    }

    async function onSubmit() {
        let produtos_para_adicionar = []
        let produtos_para_atualizar = []

        // remover todos que não possuem nome 
        // tratativa pois o CSV pode vir com o campo nome undefined
        let produtosNovosTemp = produtosNovos.filter(produto => {
            return produto.nome != undefined
        })

        produtosNovosTemp.forEach(prodNovo => {
            let temp = produtos.filter((prodAntg) => {
                return prodAntg.nome === prodNovo.nome
            })
            if (temp.length === 0) {
                produtos_para_adicionar.push(prodNovo)
            } else {
                temp[0].preco_venda = prodNovo.preco_venda
                produtos_para_atualizar.push(temp[0])
            }
        })

        const produtoRef = database.collection(chaves.PRODUTOS)

        produtos_para_adicionar.forEach(item => {
            produtoRef.add(item)
        })
        
        produtos_para_atualizar.forEach(item => {
            try{
                produtoRef.doc(item.id).set(item)
            }catch(e){
                console.log(e)
                console.log(JSON.stringify(item))
            }
        })
        setLoading(true)
    }

    return (
        <div>
            <MenuNav user={user} />
            <div className="margem">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col"><h5 className="text-success">Upload de produtos</h5></div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div>
                            <h3>Observação:</h3>
                            <p>O arquivo de importação deve possuir as seguinte colunas ( Produto | Código do Produtos | Preço de Venda )</p>
                        </div>
                        <div>
                            <CSVReader
                                ref={buttonRef}
                                onFileLoad={handleOnFileLoad}
                                onError={handleOnError}
                                noClick
                                header={true}
                                noDrag
                                onRemoveFile={handleOnRemoveFile}
                            >
                                {({ file }) => (
                                    <aside
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            marginBottom: 10
                                        }}
                                    >
                                        <button
                                            type='button'
                                            onClick={handleOpenDialog}
                                            style={{
                                                borderRadius: 3,
                                                marginLeft: 0,
                                                marginRight: 0,
                                                width: '40%',
                                                paddingLeft: 0,
                                                paddingRight: 0
                                            }}
                                        >
                                            Selecione o arquivo
                                        </button>
                                        <div
                                            style={{
                                                borderWidth: 1,
                                                borderStyle: 'solid',
                                                borderColor: '#ccc',
                                                height: 45,
                                                lineHeight: 2.5,
                                                marginTop: 5,
                                                marginBottom: 5,
                                                paddingLeft: 13,
                                                paddingTop: 3,
                                                width: '60%'
                                            }}
                                        >
                                            {file && file.name}
                                        </div>
                                        <button
                                            style={{
                                                borderRadius: 5,
                                                marginLeft: 0,
                                                marginRight: 0,
                                                paddingLeft: 20,
                                                paddingRight: 20
                                            }}
                                            onClick={handleRemoveFile}
                                        >
                                            Remover
                                        </button>
                                    </aside>
                                )}
                            </CSVReader>
                            <hr />
                            <div className="row">
                                <div className="col text-end">
                                    <button type="button"
                                        className="btn btn-primary"
                                        onClick={onSubmit}>
                                        <i className="bi bi-check-circle-fill"></i> Enviar
                                    </button>
                                </div>
                                <div>
                                    {loading && 
                                        <p>Aguarde alguns segundos antes de trocar de página pois pode haver atrasos no envio dos dados ao servidor devido a velocidade da internet</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
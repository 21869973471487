import { useEffect, useState } from "react"
import { useParams, useHistory } from 'react-router-dom'

import { MenuNav } from "../../components/MenuNav"
import { useAuth } from "../../hooks/useAuth"
import { getValorEmReais } from "../../components/Formatacao"
import { getArrayOrdenadoPorNome} from "../../components/FuncoesArray"
import { nBR_nEN } from "../../components/Conversao"
import Colecaokit from "../../db/ColecaoKit"
import ColecaoProduto from "../../db/ColecaoProduto"
import ColecaoVenda from "../../db/ColecaoVenda"
import ColecaoCliente from "../../db/ColecaoCliente"
import Venda from "../../core/Venda"

export function VendaNovo() {

    const { user } = useAuth()

    const repoKits = new Colecaokit()
    const repoProdutos = new ColecaoProduto()
    const repoVendas = new ColecaoVenda()
    const repoClientes = new ColecaoCliente()

    const history = useHistory();

    const idCliente = useParams().id

    const [cliente, setCliente] = useState({
        nome: "",
        whatsapp: "",
        endereco: "",
    })
    const [valores, setValores] = useState({
        desconto: 0,
        desconto_total: 0,
        total_bruto: 0,
        total: 0,
    })
    const [obs, setObs] = useState("")
    const [produtos, setProdutos] = useState([])
    const [kits, setKits] = useState([])
    const [produtosFilter, setProdutosFilter] = useState([])
    const [produtosVendidos, setProdutosVendidos] = useState([])
    const [pesquisado, setPesquisado] = useState("")

    //////////////////////////////////////////////////////////// Cliente
    useEffect(() => {
        repoClientes.get(idCliente).then(cliente => {
            setCliente(cliente)
        })
    }, [idCliente])

    //////////////////////////////////////////////////////////// Produtos
    useEffect(() => {
        repoProdutos.obterTodosVendaveis().then(produtos => {
            let lista = getArrayOrdenadoPorNome(produtos)
            repoKits.obterTodosComQuantidades().then(kits => {
                let listaKits = getArrayOrdenadoPorNome(kits).map(kit => kit.getMockProduto())
                lista = lista.concat(listaKits)
                setProdutos(lista)
                setProdutosFilter(lista)
                setKits(kits)
            })
        })
    }, [])

    ///////////////////////////////////////////  Atualizar valores da venda
    useEffect(() => {
        let totalTemp = produtosVendidos.reduce((total, produto) => {
            return total + produto.total_parcial
        }, 0)

        let desconto_totalTemp = (valores.desconto / 100) * totalTemp

        setValores({
            ...valores,
            total: totalTemp - desconto_totalTemp,
            desconto_total: desconto_totalTemp,
            total_bruto: totalTemp,
        })
    }, [produtosVendidos])


    function onChange(event) {
        event.preventDefault()
        let pesq = event.target.value
        setPesquisado(pesq)
        if (pesq !== "") {
            let temp = produtos.filter((obj) => {
                return obj.nome.toUpperCase().includes(pesq.toUpperCase())
            })
            if (temp === []) {
                setProdutosFilter(produtos)
            } else {
                setProdutosFilter(temp)
            }
        } else {
            setProdutosFilter(produtos)
        }

    }

    function ordenaPorNome(lista) {
        return lista.sort((a, b) => {
            if (a.nome.toUpperCase() < b.nome.toUpperCase()) {
                return -1
            } else {
                return true
            }
        })
    }

    function addProduto(id) {
        let produtoTemp = produtosFilter.find((produto) => {
            return produto.id === id
        })

        let listaTemp = produtosVendidos
        listaTemp.push(produtoTemp)
        setProdutosVendidos(
            ordenaPorNome(listaTemp)
        )

        let listaSemProduto = produtosFilter.filter((produto) => {
            return produto.id !== id
        })
        setProdutosFilter(ordenaPorNome(listaSemProduto))
    }

    function delProduto(id) {
        let produtoTemp = produtosVendidos.find((produto) => {
            return produto.id === id
        })
        let listaTemp = produtosFilter
        listaTemp.push(produtoTemp)
        setProdutosFilter(
            ordenaPorNome(listaTemp)
        )

        let listaSemProduto = produtosVendidos.filter((produto) => {
            return produto.id !== id
        })
        setProdutosVendidos(ordenaPorNome(listaSemProduto))
    }

    function changeQuantidade(id, quantidade) {
        let produtoTemp = produtosVendidos.find((produto) => {
            return produto.id === id
        })
        produtoTemp.quantidade_vendida = quantidade
        // produtoTemp.quantidade = produtoTemp.quantidade - quantidade
        produtoTemp.total_parcial = quantidade * nBR_nEN(produtoTemp.preco_venda)
        let listaTemp = produtosVendidos.filter((produto) => {
            return produto.id !== id
        })
        listaTemp.push(produtoTemp)
        setProdutosVendidos(
            ordenaPorNome(listaTemp)
        )
    }

    function setDesconto(valor) {
        // se tiver virgula não calcula
        let valor_convertido = valor === "" ? "0" : valor

        if (valor_convertido.substring(valor_convertido.length - 1) === ",") {
            setValores({
                ...valores,
                desconto: valor_convertido,
            })
        } else {
            valor_convertido = valor_convertido.replace(",", ".")
            valor_convertido = parseFloat(valor_convertido)
            let totalTemp = produtosVendidos.reduce((total, produto) => {
                return total + produto.total_parcial
            }, 0)
            let desconto_total_temp = totalTemp * (valor_convertido / 100)
            let novo_total = totalTemp - desconto_total_temp
            setValores({
                desconto: valor_convertido,
                desconto_total: desconto_total_temp,
                total: novo_total,
                total_bruto: totalTemp,
            })

        }
    }

    async function salvar() {
        
        let venda = new Venda(user, cliente, produtosVendidos,valores, obs) 

        // Atualiza a quantidade de produtos
        produtosVendidos.forEach(prod => {
            if (prod?.kit) {
                repoKits.get(prod.id).then(kit => {
                    kit.produtos.forEach(p => {
                        repoProdutos.get(p.id).then(produtoAtualizado => {
                            produtoAtualizado.quantidade -= prod.quantidade_vendida
                            repoProdutos.salvar(produtoAtualizado)
                        })
                    })
                })
            } else {
                repoProdutos.get(prod.id).then(produtoAtualizado => {
                    produtoAtualizado.quantidade -= prod.quantidade_vendida
                    repoProdutos.salvar(produtoAtualizado)
                })
            }
        })

        repoVendas.salvar(venda).then(venda => {
            history.push(`/vendas`);
        })
        
    }

    return (
        <div>
            <MenuNav user={user} />
            <div className="margem">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col"><h5 className="text-success">Venda</h5></div>
                            <div className="col text-end">
                                <button onClick={salvar} className="btn btn-warning">
                                    <i className="bi-plus-lg"></i> Finalizar Venda
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="mb-3 row">
                                <label className="col-sm-2 col-form-label">Cliente</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" value={cliente.nome} disabled />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-2 col-form-label">Desconto em %</label>
                                <div className="col-sm-3">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={valores.desconto}
                                            onChange={(event) => { setDesconto(event.target.value) }}
                                            name="desconto" />
                                    </div>
                                </div>
                                <label className="col-sm-2 col-form-label text-center">
                                    Total {getValorEmReais(valores.total_bruto)} - {getValorEmReais(valores.desconto_total)}
                                </label>
                                <label className="col-sm-2 col-form-label text-end">Total</label>
                                <div className="col-sm-3">
                                    <input type="text"
                                        className="form-control text-right"
                                        disabled value={getValorEmReais(valores.total)} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-2 col-form-label">Observações</label>
                                <div className="col-sm-10">
                                    <textarea 
                                        rows="3" 
                                        class="form-control"
                                        onChange={(event) => { setObs(event.target.value) }}>{obs}</textarea>
                                </div>
                            </div>
                        </form>
                        <div className="card">
                            <table className="table table-sm">
                                <thead>
                                    <tr className="text-center">
                                        <th scope="col">Produto</th>
                                        <th scope="col">Quant.</th>
                                        <th scope="col">Preço</th>
                                        <th scope="col">Sub</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {produtosVendidos?.map((produto, index) =>
                                        <tr key={index} >
                                            <td>
                                                {produto.nome}
                                            </td>
                                            <td className="text-center">
                                                <input
                                                    type="text"
                                                    className="form-control-sm"
                                                    value={produto.quantidade_vendida}
                                                    onChange={(event) => changeQuantidade(produto.id, event.target.value)} />
                                            </td>
                                            <td className="text-center">
                                                {getValorEmReais(produto.preco_venda)}
                                            </td>
                                            <td className="text-center">
                                                {getValorEmReais(produto.total_parcial)}
                                            </td>
                                            <td className="text-center">
                                                <button className="btn btn-sm btn-danger" onClick={() => delProduto(produto.id)} >
                                                    <i className="bi bi-dash-circle-fill"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="margem">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col">
                                <form method="get">
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control"
                                            placeholder="Pesquisar Produto" aria-label="Pesquisar Produto"
                                            aria-describedby="button-addon2"
                                            value={pesquisado} onChange={onChange} />
                                        <button className="btn btn-outline-secondary"
                                            type="button" id="button-addon2">Pesquisar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th scope="col text-start">Produto</th>
                                    <th scope="col">Quant</th>
                                    <th scope="col">Preço</th>
                                    <th scope="col">Opções</th>
                                </tr>
                            </thead>
                            <tbody>
                                {produtosFilter?.map((produto, index) =>
                                    <tr key={index} >
                                        <td>
                                            {produto.nome}
                                        </td>
                                        <td className="text-center">
                                            {produto.quantidade}
                                        </td>

                                        <td className="text-center">
                                            {getValorEmReais(produto.preco_venda)}
                                        </td>
                                        <td className="text-center">
                                            <button className="btn btn-sm btn-success" onClick={() => addProduto(produto.id)} >
                                                <i className="bi bi-plus-circle-fill"></i>
                                            </button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
import { localizarElementoPorID } from "../components/FuncoesArray";
import Kit from "../core/Kit";
import { database, chaves } from "../services/firebase"
import ColecaoProduto from "./ColecaoProduto";

export default class Colecaokit {

    colecao() {
        return database.collection(chaves.KITS).withConverter(this.KitConverter)
    }

    KitConverter = {
        toFirestore: function (kit) {
            return {
                id: kit.id,
                nome: kit.nome,
                produtos: JSON.stringify(kit.produtos),
                preco_venda: kit.preco_venda,
            };
        },
        fromFirestore: function (snapshot, options) {
            const data = snapshot.data(options);
            return new Kit(
                snapshot.id,
                data.nome,
                data.preco_venda,
                JSON.parse(data.produtos),
            );
        }
    }

    async salvar(kit) {
        if (kit?.id) {
            await this.colecao().doc(kit.id).set(kit)
            return kit
        } else {
            const docRef = await this.colecao().add(kit)
            const doc = await docRef.get()
            return doc.data()
        }
    }

    async excluir(kit) {
        return this.colecao().doc(kit.id).delete()
    }

    async obterTodos() {
        const query = await this.colecao().get()
        return query.docs.map(doc => doc.data()) ?? []
    }

    async obterTodosComQuantidades() {
        const query = await this.colecao().get()
        const repoProduto = new ColecaoProduto()
        const produtos = await repoProduto.obterTodos()
        let listaKits = query.docs.map(doc => doc.data())
        listaKits = listaKits.map(kit => {
            let listaProdutos = kit.produtos.map( p => {
               return localizarElementoPorID(p.id, produtos)[0]
            })
            kit.produtos = listaProdutos
            return kit
        })
        return listaKits ?? []
    }

    async get(id) {
        const query = await this.colecao().doc(id).get()
        return query.data() ?? Kit.vazio()
    }
}
import { useEffect, useState } from "react"
import { useParams, useHistory } from 'react-router-dom'

import { MenuNav } from "../../components/MenuNav"
import { database } from "../../services/firebase"
import { useAuth } from "../../hooks/useAuth"

export function ProducaoReceber(){

    const {user} = useAuth()

    const history = useHistory();

    const idProducao = useParams().id

    const [producao, setProducao] = useState({
        funcionario: {},
        produto: {},
        quantidade: "0",
    })

    useEffect(()=>{
        let ref = database.collection("producao").doc(idProducao).get()
        ref.then((doc)=>{
            if(doc.exists){
                setProducao({
                    id: doc.id,
                    funcionario: doc.data().funcionario,
                    produto: doc.data().produto,
                    quantidade: doc.data().quantidade,
                })
            }else{
                console.log("inexistente")
            }
        })
    }, [idProducao])

    function onChange(event){
        setProducao({
            ...producao,
            [event.target.name]: event.target.value,
        })
    }

    async function onSubmit(event){
        event.preventDefault()
        
        // calculando valor da producao 
        let valor_producao = producao.produto.custo_producao * producao.quantidade

        let registro = { producoes: [] }

        // pega as produções anteriores 
        let ref = database.collection("producao_funcionario").doc(producao.funcionario.id).get()
        ref.then((doc)=>{
            if(doc.exists){
                let registros_antigos = doc.data()
                registros_antigos.producoes.push({
                    data: new Date(), 
                    funcionario: producao.funcionario, 
                    produto: producao.produto, 
                    quantidade: producao.quantidade, 
                    valor: valor_producao,
                })
                registro = registros_antigos
            }else{
                registro.producoes.push({
                    data: new Date(), 
                    funcionario: producao.funcionario, 
                    produto: producao.produto, 
                    quantidade: producao.quantidade, 
                    valor: valor_producao,
                })
            }
            // salva no banco
            database.collection("producao_funcionario").doc(producao.funcionario.id).set(registro)
            // remove o registro de produções        
            database.collection("producao").doc(idProducao).delete()
            // atualiza as quantidade no banco 
            let refProduto = database.collection("produtos").doc(producao.produto.id).get()
            refProduto.then((doc)=>{
                let temp = doc.data()
                temp.quantidade = temp.quantidade + producao.quantidade
                database.collection("produtos").doc(producao.produto.id).set(temp)
                history.push(`/producao`);
            })
        })
    }

    return (
        <div>
            <MenuNav user={user}/>
            <div className="margem">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col"><h5 className="text-success">Editar</h5></div>
                        </div>
                    </div>
                    <div className="card-body">
                        <form onSubmit={onSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Funcionario</label>
                                <input type="text" className="form-control" disabled
                                    value={producao.funcionario.nome}/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Produto</label>
                                <input type="text" className="form-control" disabled
                                    value={producao.produto.nome} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Quantidade</label>
                                <input type="text" className="form-control"
                                    name="quantidade" value={producao.quantidade} onChange={onChange} required/>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <a href="/producao" className="btn btn-secondary">
                                        <i className="bi bi-arrow-left-circle-fill"></i> Voltar
                                    </a>
                                </div>
                                <div className="col text-end">
                                    <button type="submit" className="btn btn-primary">
                                        <i className="bi bi-check-circle-fill"></i> Receber
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>       
    )
}
import Venda from "../core/Venda";
import { database, chaves } from "../services/firebase"

export default class ColecaoVenda {

    colecaoVendas() {
        return database.collection(chaves.VENDAS).withConverter(this.VendaConverter)
    }

    colecaoVendasClientes() {
        return database.collection(chaves.VENDAS_CLIENTES)
    }

    colecaoVendasProdutos() {
        return database.collection(chaves.VENDAS_PRODUTOS)
    }

    VendaConverter = {
        toFirestore: function (venda) {
            return {
                data: venda.data,
                vendedor: JSON.stringify(venda.vendedor),
                cliente: JSON.stringify(venda.cliente),
                produtos: JSON.stringify(venda.produtos),
                valores: JSON.stringify(venda.valores),
                obs: venda.obs,
            };
        },
        fromFirestore: function (snapshot, options) {
            const data = snapshot.data(options);
            let resp = new Venda(
                JSON.parse(data.vendedor),
                JSON.parse(data.cliente),
                JSON.parse(data.produtos),
                JSON.parse(data.valores),
                data.obs,
                new Date(data.data.seconds * 1000),
                snapshot.id,
            );
            return resp
        }
    }

    async salvar(venda) {
        if (venda?.id) {
            await this.colecaoVendas().doc(venda.id).set(venda)
            return venda
        } else {
            const docRef = await this.colecaoVendas().add(venda)
            const doc = await docRef.get()
            return doc.data()
        }
    }

    async excluir(venda) {
        return this.colecaoVendas().doc(venda.id).delete()
    }

    async obterTodos() {
        const query = await this.colecaoVendas().orderBy("data").get()
        return query.docs.map(doc => doc.data()) ?? []
    }

    async get(id) {
        const query = await this.colecaoVendas().doc(id).get()
        return query.data() ?? Venda.vazio()
    }
}
export default function TabelaProdutosDoKit(props) {
    return (
        <table className="table table-striped table-hover">
            <tbody>
                {props.produtos?.map((produto) => {
                    return (
                        <tr key={produto.id}>
                            <td>{produto.nome}</td>
                            <td className="text-end">
                                <button className="btn btn-sm btn-danger"
                                    onClick={() => {
                                        if (window.confirm(`Confirma remoção deste item ${produto.nome}`)) props.removeProduto?.(produto)
                                    }}>
                                    <i className="bi bi-x-circle-fill"></i>
                                </button>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}
import Cliente from "./Cliente"
import Produto from "./Produto"

export default class Venda {
    constructor(user, cliente, produtos, valores, obs, data = new Date(), id = "") {
        this.id = id;
        this.data = data;
        this.vendedor = user;
        this.cliente = cliente;
        this.produtos = produtos;
        this.valores = valores;
        this.obs = obs;
    }

    static vazio() {
        return new Venda({}, new Cliente(), [new Produto()], {})
    }
}
function getArrayOrdenadoPorNome(array){
    return array.sort((a, b) => {
        if (a.nome.toUpperCase() < b.nome.toUpperCase()) {
            return -1
        } else {
            return true
        }
    })
}

function removeElementoDoArrayPorID(id,array){
    return array.filter((obj) => {
        return obj.id !== id
    })
}
function localizarElementoPorID(id, array){
    return array.filter((obj) => {
        return obj.id === id
    })
}

export { getArrayOrdenadoPorNome, removeElementoDoArrayPorID, localizarElementoPorID }
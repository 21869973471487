import { useEffect, useState } from "react"
import { MenuNav } from "../../components/MenuNav"
import { database, chaves } from "../../services/firebase"
import { useAuth } from "../../hooks/useAuth"

export function ClienteList() {

    const { user } = useAuth()
    const [clientes, setClientes] = useState([])
    const [clientesFilter, setClientesFilter] = useState([])
    const [pesquisado, setPesquisado] = useState("")

    useEffect(() => {
        async function getClientes() {
            let ref = await database.collection(chaves.CLIENTES).get()
            let lista = ref.docs.map((doc) => {
                return {
                    id: doc.id,
                    nome: doc.data().nome,
                    email: doc.data().email,
                    tel_principal: doc.data().tel_principal
                }
            })
            lista = lista.sort((a, b) => {
                if (a.nome.toUpperCase() < b.nome.toUpperCase()) {
                    return -1
                } else {
                    return true
                }
            })
            setClientes(lista)
            setClientesFilter(lista)
        }
        getClientes()
    }, [])

    function onChange(event) {
        event.preventDefault()
        let pesq = event.target.value
        setPesquisado(pesq)
        if (pesq !== "") {
            let temp = clientes.filter((obj) => {
                return obj.nome.toUpperCase().includes(pesq.toUpperCase())
            })
            if (temp === []) {
                setClientesFilter(clientes)
            } else {
                setClientesFilter(temp)
            }
        } else {
            setClientesFilter(clientes)
        }
    }

    function excluir(id) {
        // excluir das duas listas 
        let clientesTemp = clientes.filter((obj) => {
            return obj.id !== id
        })
        setClientes(clientesTemp)
        setClientesFilter(clientesTemp)

        database.collection(chaves.CLIENTES).doc(id).delete()
    }

    return (
        <div>
            <MenuNav user={user} />
            <div className="margem">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col"><h5 className="text-success">Lista de Cliente</h5></div>
                            <div className="col text-end">
                                <a href="/clientes/novo" className="btn btn-warning">
                                    <i className="bi-plus-lg"></i> Novo Cliente
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <form method="get">
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control"
                                            placeholder="Pesquisar Cliente" aria-label="Pesquisar Cliente"
                                            aria-describedby="button-addon2"
                                            value={pesquisado} onChange={onChange} />
                                        <button className="btn btn-outline-secondary"
                                            type="button" id="button-addon2">Pesquisar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th scope="col text-start">Nome</th>
                                    <th scope="col">Whatsapp</th>
                                    <th scope="col">Opções</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clientesFilter?.map((cliente, index) =>
                                    <tr key={index}>
                                        <td>{cliente.nome}</td>
                                        <td className="text-center">{cliente.tel_principal}</td>
                                        <td className="text-end">
                                            <a className="btn btn-sm btn-warning" href={`/clientes/${cliente.id}`} title="Editar">
                                                <i className="bi bi-pencil"></i>
                                            </a> <button className="btn btn-sm btn-danger"
                                                onClick={() => { if (window.confirm(`Confirma a exclusão do cliente (${cliente.nome})?`)) excluir(cliente.id) }}>
                                                <i className="bi bi-x-circle-fill"></i>
                                            </button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
import { useEffect, useState } from "react"
import { MenuNav } from "../../components/MenuNav"
import { database, chaves } from "../../services/firebase"
import { useAuth } from "../../hooks/useAuth"

export function ProdutoList(){
    const {user} = useAuth()

    const [produtos, setProdutos] = useState([])
    const [produtosFilter, setProdutosFilter] = useState([])
    const [pesquisado, setPesquisado] = useState("")

    useEffect(()=>{
        async function getprodutos(){
            let ref = await database.collection(chaves.PRODUTOS).get()
            let lista = ref.docs.map((doc) => {
                return {
                    id: doc.id,
                    nome: doc.data().nome,
                    quantidade: doc.data().quantidade,
                    quantidade_min: doc.data().quantidade_min,
                    custo_producao: doc.data().custo_producao,
                    preco_venda: doc.data().preco_venda,
                    e_vendido: doc.data().e_vendido, 
                    destaque: doc.data().quantidade < doc.data().quantidade_min ? "table-warning " : ""
                }
            })
            lista = lista.sort((a, b)=>{
                if(a.nome.toUpperCase() < b.nome.toUpperCase()){
                    return -1
                }else{
                    return true
                }
            })
            setProdutos(lista)
            setProdutosFilter(lista)
        }
        getprodutos()
    }, [])

    function onChange(event){
        event.preventDefault()
        let pesq = event.target.value
        setPesquisado(pesq)
        if(pesq !== ""){
            let temp = produtos.filter( (obj) => {
                return obj.nome.toUpperCase().includes(pesq.toUpperCase())
            })
            if(temp === []){
                setProdutosFilter(produtos)
            }else{
                setProdutosFilter(temp)
            }
        }else{
            setProdutosFilter(produtos)
        }
    }

    function excluir(id) {
        // excluir das duas listas 
        let produtosTemp = produtos.filter((obj) => {
            return obj.id != id
        })
        setProdutos(produtosTemp)
        setProdutosFilter(produtosTemp)

        database.collection(chaves.PRODUTOS).doc(id).delete()
    }

    return (
        <div>
            <MenuNav user={user}/>
            <div className="margem">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col"><h5 className="text-success">Lista de Produtos</h5></div>
                            <div className="col text-end">
                                <a href="/produtos/novo" className="btn btn-warning">
                                    <i className="bi-plus-lg"></i> Novo Produto
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <form method="get">
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" 
                                            placeholder="Pesquisar Produtos" aria-label="Pesquisar Produtos" 
                                            aria-describedby="button-addon2" 
                                            value={pesquisado} onChange={onChange}/>
                                        <button className="btn btn-outline-secondary" 
                                            type="button" id="button-addon2">Pesquisar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <table className="table table-striped table-hover">
                            <thead>
                            <tr className="text-center">
                                <th scope="col text-start">Nome</th>
                                <th scope="col">Quant</th>
                                <th scope="col">Opções</th>
                            </tr>
                            </thead>
                            <tbody>
                            {produtosFilter?.map((produto, index) => 
                                <tr key={index} className={produto.destaque} >
                                    <td>
                                        <a href={`/produtos/${produto.id}`}>
                                            {produto.nome}
                                        </a> 
                                    </td>
                                    <td className="text-center">
                                        {produto.quantidade}
                                    </td>
                                    <td className="text-center">
                                        <a className="btn btn-sm btn-success" href={`/produtos/${produto.id}`} title="Editar">
                                            <i className="bi bi-plus-circle-fill"></i>
                                        </a>  <button className="btn btn-sm btn-danger"
                                                onClick={() => { if (window.confirm(`Confirma a exclusão do produto (${produto.nome})?`)) excluir(produto.id) }}>
                                                <i className="bi bi-x-circle-fill"></i>
                                            </button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>       
    )
}
import { useEffect, useState } from "react"
import { MenuNav } from "../../components/MenuNav"
import { database } from "../../services/firebase"
import { useAuth } from "../../hooks/useAuth"

export function FuncionarioList(){

    const {user} = useAuth()

    const [funcionarios, setFuncionarios] = useState([])
    const [funcionarioFilter, setFuncionarioFilter] = useState([])
    const [pesquisado, setPesquisado] = useState("")

    useEffect(()=>{
        async function getFuncionarios(){
            let ref = await database.collection("funcionarios").get()
            let lista = ref.docs.map((doc) => {
                return {
                    id: doc.id,
                    nome: doc.data().nome,
                    whatsapp: doc.data().whatsapp,
                    endereco: doc.data().endereco,
                }
            })
            lista = lista.sort((a, b)=>{
                if(a.nome.toUpperCase() < b.nome.toUpperCase()){
                    return -1
                }else{
                    return true
                }
            })
            setFuncionarios(lista)
            setFuncionarioFilter(lista)
        }
        getFuncionarios()
    }, [])

    function onChange(event){
        event.preventDefault()
        let pesq = event.target.value
        setPesquisado(pesq)
        if(pesq !== ""){
            let temp = funcionarios.filter( (obj) => {
                return obj.nome.toUpperCase().includes(pesq.toUpperCase())
            })
            if(temp === []){
                setFuncionarioFilter(funcionarios)
            }else{
                setFuncionarioFilter(temp)
            }
        }else{
            setFuncionarioFilter(funcionarios)
        }
    }

    return (
        <div>
            <MenuNav user={user}/>
            <div className="margem">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col"><h5 className="text-success">Lista de Funcionários</h5></div>
                            <div className="col text-end">
                                <a href="/funcionarios/novo" className="btn btn-warning">
                                    <i className="bi-plus-lg"></i> Novo Funcionário
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <form method="get">
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" 
                                            placeholder="Pesquisar Funcionários" aria-label="Pesquisar Funcionários" 
                                            aria-describedby="button-addon2" 
                                            value={pesquisado} onChange={onChange}/>
                                        <button className="btn btn-outline-secondary" 
                                            type="button" id="button-addon2">Pesquisar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <table className="table table-striped table-hover">
                            <thead>
                            <tr className="text-center">
                                <th scope="col text-start">Nome</th>
                                <th scope="col">Whatsapp</th>
                                <th scope="col">Opções</th>
                            </tr>
                            </thead>
                            <tbody>
                            {funcionarioFilter?.map((funcionario, index) => 
                                <tr key={index}>
                                    <td>{funcionario.nome}</td>
                                    <td className="text-center">{funcionario.whatsapp}</td>
                                    <td className="text-center">
                                        <a className="btn btn-sm btn-warning" href={`/funcionarios/${funcionario.id}`} title="Editar">
                                            <i className="bi bi-pencil"></i>
                                        </a> <a className="btn btn-sm btn-primary" href={`/producao_funcionario/${funcionario.id}`} title="Editar">
                                            <i className="bi bi-gear-wide-connected"></i>
                                        </a>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>       
    )
}
export default class Cliente {
    constructor(nome, tel_principal, tel_secundario, email, cnpj_cpf, estado, cidade, bairro, endereco, numero, cep, insc_estadual, id = "") {
        this.nome = nome;
        this.tel_principal = tel_principal;
        this.tel_secundario = tel_secundario;
        this.email = email;
        this.cnpj_cpf = cnpj_cpf;
        this.estado = estado;
        this.cidade = cidade;
        this.bairro = bairro;
        this.endereco = endereco;
        this.numero = numero;
        this.cep = cep;
        this.insc_estadual = insc_estadual;
        this.id = id;
    }

    getCNPJ_CPF() {
        if (this.cnpj_cpf === undefined) {
            return ""
        } else {
            return this.cnpj_cpf
        }
    }

    getInsc_Estadual(){
        if (this.insc_estadual === undefined) {
            return ""
        } else {
            return this.insc_estadual
        }
    }

    static vazio() {
        return new Cliente("", "", "", "", "", "", "", "", "", "", "", "")
    }

}
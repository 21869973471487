import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';
import "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBJeBkLkx2AVtDsc8D7_LkaCWk1gNz1aCc",
    authDomain: "alusaraiva-controle.firebaseapp.com",
    databaseURL: "https://alusaraiva-controle.firebaseio.com",
    projectId: "alusaraiva-controle",
    storageBucket: "alusaraiva-controle.appspot.com",
    messagingSenderId: "8926690185",
    appId: "1:8926690185:web:b0a729a767d9033968bf58"
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const database = firebase.firestore();
const storage = firebase.storage();

const chaves = {
    PRODUTOS: "produtos",
    CLIENTES: "clientes",
    VENDAS: "vendas",
    VENDAS_CLIENTES: "vendas-clientes",
    VENDAS_PRODUTOS: "vendas-produtos",
    KITS: "kits",
}

export { firebase, auth, database, storage, chaves }
import { useState, useEffect } from "react"
import { useHistory } from 'react-router-dom'

import { MenuNav } from "../../components/MenuNav"
import { database } from "../../services/firebase"
import { useAuth } from "../../hooks/useAuth"

export function ProducaoNovo(){

    const {user} = useAuth()

    const history = useHistory();

    const [producao, setProducao] = useState({
        data: "",
        funcionario_id: "",
        produto_id: "",
        quantidade: "",
        valor: "", 
    })
    const [funcionarios, setFuncionarios] = useState([])
    const [produtos, setProdutos] = useState([])

    useEffect(()=>{
        async function getFuncionarios(){
            let ref = await database.collection("funcionarios").get()
            let listaFuncionarios = ref.docs.map((doc) => {
                return {
                    id: doc.id,
                    nome: doc.data().nome,
                }
            })
            setFuncionarios(ordenaPorNome(listaFuncionarios))
        }
        async function getProdutos(){
            let ref = await database.collection("produtos").get()
            let listaProdutos = ref.docs.map((doc) => {
                return {
                    id: doc.id,
                    nome: doc.data().nome,
                    custo_producao: doc.data().custo_producao,
                }
            })
            setProdutos(ordenaPorNome(listaProdutos))
        }
        getFuncionarios()
        getProdutos()
    }, [])

    function ordenaPorNome(lista){
        return lista.sort((a, b)=>{
            if(a.nome.toUpperCase() < b.nome.toUpperCase()){
                return -1
            }else{
                return true
            }
        })
    }

    function onChange(event){
        setProducao({
            ...producao,
            [event.target.name]: event.target.value,
        })
    }

    async function onSubmit(event){
        event.preventDefault()

        let produto = produtos.find( (obj) => {
            return obj.id.includes(producao.produto_id)
        })
        let funcionario = funcionarios.find( (obj) => {
            return obj.id.includes(producao.funcionario_id)
        })
        
        const ref = database.collection("producao")
        await ref.add({
            funcionario: funcionario,
            produto: produto,
            quantidade: parseInt(producao.quantidade),
        })
        history.push(`/producao`);
    }

    return (
        <div>
            <MenuNav user={user}/>
            <div className="margem">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col"><h5 className="text-success">Nova Produção</h5></div>
                        </div>
                    </div>
                    <div className="card-body">
                        <form onSubmit={onSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Funcionário*</label>
                                <select className="form-select" name="funcionario_id" 
                                    value={producao.funcionario_id} onChange={onChange} required>
                                    {funcionarios?.map((funcionario, index) => 
                                        <option key={index} value={funcionario.id}>{funcionario.nome}</option>
                                    )}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Produto*</label>
                                <select className="form-select" name="produto_id" 
                                    value={producao.produto_id} onChange={onChange} required>
                                    {produtos?.map((produto, index) => 
                                        <option key={index} value={produto.id}>{produto.nome}</option>
                                    )}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Quantidade</label>
                                <input type="text" className="form-control"
                                    name="quantidade" value={producao.quantidade} onChange={onChange} />
                            </div>
                            
                            <div className="row">
                                <div className="col">
                                    <a href="/clientes" className="btn btn-secondary">
                                        <i className="bi bi-arrow-left-circle-fill"></i> Voltar
                                    </a>
                                </div>
                                <div className="col text-end">
                                    <button type="submit" className="btn btn-primary">
                                        <i className="bi bi-check-circle-fill"></i> Salvar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>       
    )
}
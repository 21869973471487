export default class Kit {
    constructor(id, nome, preco_venda, produtos = []) {
        this.id = id;
        this.nome = nome;
        this.produtos = produtos;
        this.preco_venda = preco_venda;
    }

    static vazio() {
        return new Kit("", "", 0)
    }

    getQuant() {
        let resp = 10000
        this.produtos.forEach(p => {
            if (parseInt(p.quantidade) < resp) {
                resp = p.quantidade
            }
        })
        return resp
    }

    getMockProduto() {
        return {
            id: this.id,
            nome: this.nome,
            quantidade: this.getQuant(),
            quantidade_min: 0,
            custo_producao: 0,
            preco_venda: this.preco_venda,
            e_vendido: true,
            kit: true,
        }
    }

}
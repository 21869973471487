import { useEffect, useState } from "react"
import { MenuNav } from "../../components/MenuNav"
import { database, chaves } from "../../services/firebase"
import { useAuth } from "../../hooks/useAuth"

export function VendaSelecionaCliente(){
    const {user} = useAuth()

    const [clientes, setClientes] = useState([])
    const [clientesFilter, setClientesFilter] = useState([])
    const [pesquisado, setPesquisado] = useState("")

    useEffect(()=>{
        async function getClientes(){
            let ref = await database.collection(chaves.CLIENTES).get()
            let lista = ref.docs.map((doc) => {
                return {
                    id: doc.id,
                    nome: doc.data().nome,
                    email: doc.data().email,
                }
            })
            lista = lista.sort((a, b)=>{
                if(a.nome.toUpperCase() < b.nome.toUpperCase()){
                    return -1
                }else{
                    return true
                }
            })
            setClientes(lista)
            setClientesFilter(lista)
        }
        getClientes()
    }, [])

    function onChange(event){
        event.preventDefault()
        let pesq = event.target.value
        setPesquisado(pesq)
        if(pesq !== ""){
            let temp = clientes.filter( (obj) => {
                return obj.nome.toUpperCase().includes(pesq.toUpperCase())
            })
            if(temp === []){
                setClientesFilter(clientes)
            }else{
                setClientesFilter(temp)
            }
        }else{
            setClientesFilter(clientes)
        }
    }

    return (
        <div>
            <MenuNav user={user}/>
            <div className="margem">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col"><h5 className="text-success">Selecionar Cliente</h5></div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <form method="get">
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" 
                                            placeholder="Pesquisar Cliente" aria-label="Pesquisar Cliente" 
                                            aria-describedby="button-addon2" 
                                            value={pesquisado} onChange={onChange}/>
                                        <button className="btn btn-outline-secondary" 
                                            type="button" id="button-addon2">Pesquisar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <table className="table table-striped table-hover">
                            <thead>
                            <tr className="text-start">
                                <th scope="col">Nome</th>
                                <th scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                            {clientesFilter?.map((cliente, index) => 
                                <tr key={index}>
                                    <td>{cliente.nome}</td>
                                    <td className="text-end">
                                        <a className="btn btn-sm btn-warning" href={`/vendas/nova_venda/${cliente.id}`} title="Editar">
                                            <i className="bi bi-arrow-right-circle-fill"></i> Iniciar Venda
                                        </a>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>       
    )
}
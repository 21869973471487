import { MenuNav } from "../../components/MenuNav"
import { useAuth } from "../../hooks/useAuth"

export function Dashboard(){

    const {user} = useAuth() 

    return (
        <div>
            <MenuNav user={user}/>
        </div>       
    )
}
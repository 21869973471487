import { useEffect, useState } from "react"
import DatePicker from 'react-date-picker'
import { MenuNav } from "../../components/MenuNav"
import { useAuth } from "../../hooks/useAuth"
import { getValorEmReais } from "../../components/Formatacao"
import ColecaoVenda from "../../db/ColecaoVenda"
import { dateToDateBR } from "../../components/Conversao"

export function VendaList() {

    const repoVendas = new ColecaoVenda()

    const { user } = useAuth()

    const [vendas, setVendas] = useState([])
    const [vendasFiltro, setVendasFiltro] = useState([])

    //const [total, setTotal] = useState("0,00")

    const date = new Date()
    const [dataInicio, setDataInicio] = useState(new Date(date.getFullYear(), date.getMonth(), 1, 0, 1, 1))
    const [dataFinal, setDataFinal] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59))

    // carregar vendas 
    useEffect(() => {
        repoVendas.obterTodos().then(vendas => {
            let lista = ordenaLista(vendas)
            setVendas(lista)
            setVendasFiltro(filtarPorPeriodo(lista))
        })
    }, [])


    function ordenaLista(lista) {
        return lista.sort((a, b) => {
            if (a.data.getTime < b.data.getTime) {
                return -1
            } else {
                return true
            }
        })
    }

    function filtarPorPeriodo(vendasTemp) {
        let filtro = []
        if (dataInicio === null || dataFinal === null) {
            filtro = []
        } else {
            let data_i = new Date(
                dataInicio.getFullYear(), dataInicio.getMonth(), dataInicio.getDate(), 0, 1, 1
            )
            let data_f = new Date(
                dataFinal.getFullYear(), dataFinal.getMonth(), dataFinal.getDate(), 23, 59, 59
            )
            filtro = vendasTemp.filter((venda) => {
                return venda.data?.getTime() > data_i.getTime() && venda.data?.getTime() < data_f.getTime()
            })
        }
        return filtro
    }

    // Quando a data for atualizada 
    useEffect(() => {
        setVendasFiltro(filtarPorPeriodo(vendas))
    }, [dataInicio, dataFinal])


    // Atualizar Total
    useEffect(() => {

    }, [vendasFiltro])

    return (
        <div>
            <MenuNav user={user} />
            <div className="margem">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col"><h5 className="text-success">Vendas</h5></div>
                            <div className="col text-end">
                                <a href="/vendas/selecionar_cliente" className="btn btn-warning">
                                    <i className="bi-plus-lg"></i> Nova Venda
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <form>
                                <span>Filtro por período - Início </span>
                                <DatePicker
                                    onChange={setDataInicio}
                                    value={dataInicio}
                                    locale="pt-br"
                                />
                                <span> Término </span>
                                <DatePicker
                                    onChange={setDataFinal}
                                    value={dataFinal}
                                    locale="pt-br"
                                    clearAriaLabel="Clear value"
                                />
                            </form>
                        </div>
                        <br />
                        <div className="row">
                            <div className="input-group mb-3">
                                <input type="text" className="form-control" placeholder="Pesquisa por Cliente" aria-label="Pesquisa por Cliente" aria-describedby="basic-addon2" />
                                <span className="input-group-text" id="basic-addon2">Pesquisar</span>
                            </div>
                        </div>
                        <hr />

                        <table className="table table-striped table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th scope="col">Data</th>
                                    <th scope="col">Cliente</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Opções</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vendasFiltro?.map((venda, index) =>
                                    <tr key={index}>
                                        <td className="text-center">{dateToDateBR(venda.data)}</td>
                                        <td className="text-center">{venda.cliente.nome}</td>
                                        <td className="text-center">{getValorEmReais(venda.valores.total)}</td>
                                        <td className="text-center">
                                            <a type="button" className="btn btn-primary"
                                                href={`/vendas/detalhe/${venda.id}`}>
                                                <i className="bi-cart4"></i> Detalhes
                                            </a>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
import Produto from "../core/Produto";
import { database, chaves } from "../services/firebase"

export default class ColecaoProduto {

    colecao() {
        return database.collection(chaves.PRODUTOS).withConverter(this.ProdutoConverter)
    }

    ProdutoConverter = {
        toFirestore: function (produto) {
            return {
                id: produto.id,
                nome: produto.nome,
                quantidade: produto.quantidade,
                quantidade_min: produto.quantidade_min,
                custo_producao: produto.custo_producao,
                preco_venda: produto.preco_venda,
                e_vendido: produto.e_vendido,
            };
        },
        fromFirestore: function (snapshot, options) {
            const data = snapshot.data(options);
            return new Produto(
                data.nome,
                data.quantidade,
                data.quantidade_min,
                data.custo_producao,
                data.preco_venda,
                data.e_vendido,
                snapshot.id,);
        }
    }

    async salvar(produto) {
        if (produto?.id) {
            await this.colecao().doc(produto.id).set(produto)
            return produto
        } else {
            const docRef = await this.colecao().add(produto)
            const doc = await docRef.get()
            await this.colecao().doc(doc.data().id).set(doc.data())
            return doc.data()
        }
    }

    async excluir(produto) {
        return this.colecao().doc(produto.id).delete()
    }

    async obterTodos() {
        const query = await this.colecao().orderBy("nome").get()
        return query.docs.map(doc => doc.data()) ?? []
    }

    async obterTodosVendaveis() {
        const query = await this.colecao().where("e_vendido","==", true).get()
        return query.docs.map(doc => doc.data()) ?? []
    }

    async get(id) {
        const query = await this.colecao().doc(id).get()
        return query.data() ?? Produto.vazio()
    }

    //async filterKit(kit){
    //    const query = await this.colecao().where("id_kit", "==", kit.id ).get()
    //    return query.docs.map(doc => doc.data()) ?? []
    //}

}
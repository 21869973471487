import { BrowserRouter, Route, Switch } from "react-router-dom"
import { AuthContextProvider } from "./services/auth_firebase"
import { Home } from "./pages/home/Home"
import { HomeLogin } from "./pages/home/HomeLogin"
import { Dashboard } from "./pages/dashboard/Dashboard"
import { ClienteList } from "./pages/cliente/ClienteList"
import { ClienteNovo } from "./pages/cliente/ClienteNovo"
import { ClienteEdit } from "./pages/cliente/ClienteEdit"
import { ProdutoList } from "./pages/produto/ProdutoList"
import { ProdutoNovo } from "./pages/produto/ProdutoNovo"
import { ProdutoImport } from "./pages/produto/ProdutoImport"
import { ProdutoExport } from "./pages/produto/ProdutoExport"
import { ProdutoEdit } from "./pages/produto/ProdutoEdit"
import { FuncionarioList } from "./pages/funcionario/FuncionarioList"
import { FuncionarioNovo } from "./pages/funcionario/FuncionarioNovo"
import { FuncionarioEdit } from "./pages/funcionario/FuncionarioEdit"
import { ProducaoList } from "./pages/producao/ProducaoList"
import { ProducaoNovo } from "./pages/producao/ProducaoNovo"
import { ProducaoReceber } from "./pages/producao/ProducaoReceber"
import { FuncionarioProducao } from "./pages/funcionario/FuncionarioProducao"
import { VendaList } from "./pages/venda/VendaList"
import { VendaSelecionaCliente } from "./pages/venda/VendaSelecionaCliente"
import { VendaNovo } from "./pages/venda/VendaNovo"
import { VendaDetalhe } from "./pages/venda/VendaDetalhe"
import { ClienteImport } from "./pages/cliente/ClienteImport"
import { KitList } from "./pages/kit/KitList"
import { KitForm } from "./pages/kit/KitForm"
import { KitEdit } from "./pages/kit/KitEdit"



function App() {
  return (
    <BrowserRouter>

      <Switch>
        <AuthContextProvider>
          <Route path="/" exact component={Home} />
          <Route path="/sistema" exact component={HomeLogin} />
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/clientes" exact component={ClienteList} />
          <Route path="/clientes/novo" exact component={ClienteNovo} />
          <Route path="/clientes_importar" exact component={ClienteImport} />
          <Route path="/clientes/:id" component={ClienteEdit} />
          <Route path="/produtos" exact component={ProdutoList} />
          <Route path="/produtos/novo" exact component={ProdutoNovo} />
          <Route path="/produto_import" exact component={ProdutoImport} />
          <Route path="/produto_export" exact component={ProdutoExport} />
          <Route path="/produtos/:id" component={ProdutoEdit} />
          <Route path="/funcionarios" exact component={FuncionarioList} />
          <Route path="/funcionarios/novo" exact component={FuncionarioNovo} />
          <Route path="/funcionarios/:id" component={FuncionarioEdit} />
          <Route path="/producao" exact component={ProducaoList} />
          <Route path="/producao/novo" exact component={ProducaoNovo} />
          <Route path="/producao/receber/:id" component={ProducaoReceber} />
          <Route path="/producao_funcionario/:id" component={FuncionarioProducao} />
          <Route path="/vendas" exact component={VendaList} />
          <Route path="/vendas/selecionar_cliente" exact component={VendaSelecionaCliente} />
          <Route path="/vendas/nova_venda/:id" component={VendaNovo} />
          <Route path="/vendas/detalhe/:id" component={VendaDetalhe} />
          <Route path="/kits" exact component={KitList} />
          <Route path="/kits/formulario/" exact component={KitForm} />
          <Route path="/kits/edit/:id" component={KitEdit} />
        </AuthContextProvider>
      </Switch>
    </BrowserRouter>
  );
}

export default App;